module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src1796175490/src/sethness-roquette-website/src/components/Layout/Layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-TDH43NQGD3","cookieName":"gatsby-gdpr-google-analytics","allowAdFeatures":false,"anonymize":true},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"43a1fe8f226f956302f41447fe234963"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sethness Roquette","short_name":"Sethness Roquette","start_url":"/","theme_color":"#d07830","background_color":"#ffffff","icon":"src/assets/images/logos/sr-icon-color.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"43a1fe8f226f956302f41447fe234963"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["en","fr","es","cn"],"defaultLanguage":"en","generateDefaultLanguagePage":true,"redirect":false,"siteUrl":"https://www.sethness-roquette.com/","trailingSlash":"always","i18nextOptions":{"keySeparator":"."},"pages":[{"matchPath":"/:lang"},{"matchPath":"/:lang/:id"},{"matchPath":"/:lang/about-us/:id"},{"matchPath":"/:lang/application/:id"},{"matchPath":"/:lang/caramel/:id"},{"matchPath":"/:lang/documentation/:id"},{"matchPath":"/:lang/legal/:id"}]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
